<template>
    <div>
        <ReviewFilter v-if="$can('filter', 'reviews')" :loading="loading" @searchFilter="searchFilter"
            @filterPage="filterPage" />
        <b-card>
            <TitleTable titleTable="Review of System" iconTable="fas fa-disease" />
            <div class="m-1">
                <ReviewEdit v-if="isEditReviewSidebarActive" :is-edit-review-sidebar-active.sync="isEditReviewSidebarActive"
                    :itemEdit="itemEdit" @editReviewSuccess="editReviewSuccess" />
                <ReviewAdd :is-add-new-review-sidebar-active.sync="isAddNewReviewSidebarActive"
                    @createReview="createReview" />
                <!-- Table Top -->
                <b-row>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <treeselect v-model="perPage" :options="perPageOptions" :clearable="true" class="w-25" />
                    </b-col>
                    <!-- {{ $t("Search") }} -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <GoBack class="mr-2" />
                            <b-button v-if="$can('store', 'reviews')" variant="primary"
                                @click="isAddNewReviewSidebarActive = true" :disabled="loading">
                                <span v-if="!loading" class="text-nowrap">{{ $t("Add") }} Review</span>
                                <span v-else>
                                    <SpinnerLoading />
                                </span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table :items="items" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
                show-empty empty-text="No matching records found">
                <template #cell(category)="data">
                    <div class="text-nowrap">
                        <span class="align-text-top text-capitalize">{{
                            data.item.category.name
                        }}</span>
                    </div>
                </template>
                <template #cell(status)="data">
                    <div class="text-nowrap">
                        <span class="align-text-top text-capitalize">{{
                            data.item.status ? $t("Active") : $t("Inactive")
                        }}</span>
                    </div>
                </template>
                <template #cell(actions)="data">
                    <b-button v-if="$can('update', 'reviews')" variant="outline-primary"
                        class="btn-icon btn-circle border-0" v-b-tooltip.hover.top="'Edit'" @click="editReview(data.item)">
                        <i class="fas fa-pen icon-nm"></i>
                    </b-button>

                    <b-button v-if="$can('destroy', 'reviews')" variant="outline-danger"
                        class="btn-icon btn-circle border-0" v-b-tooltip.hover.top="'Delete'"
                        @click="deleteReview(data.item)">
                        <i class="fas fa-trash-alt icon-nm"></i>
                    </b-button>
                </template>
            </b-table>
            <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
                @page-changed="pageChanged" />
        </b-card>
        <ToastNotification ref="toast" />
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    VBTooltip,
} from "bootstrap-vue";
import axiosAC from "@/core/services/apiInsurance/admin/consults/reviewSystems";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";
import Swal from "sweetalert2";

import ReviewEdit from "./ReviewEdit.vue";
import ReviewAdd from "./ReviewAdd.vue";
import ReviewFilter from "./ReviewFilter.vue";
import { ref } from "@vue/composition-api";
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BTable,
        BBadge,
        BAvatar,
        BButton,
        ReviewEdit,
        ReviewAdd,
        ReviewFilter,
        ToastNotification,
        SpinnerLoading,
        PaginationTable,
        TitleTable,
        GoBack,
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            fields: [
                // A virtual column that doesn't exist in items
                "id",
                { key: "name", label: "Name", sortable: true },
                { key: "category", sortable: true },
                { key: "status", label: "Status" },
                // A virtual column made up from two fields
                { key: "actions", label: "Actions" },
            ],
            items: null,
            loading: false,
            isEditReviewSidebarActive: false,
            isAddNewReviewSidebarActive: false,
            itemEdit: null,
            dataMeta: { from: 1, to: 1, of: 1 },
            totalUsers: 0,
            perPage: 10,
            currentPage: {
                page: 1,
            },
            perPageOptions: [10, 25, 50, 100].map((i) => ({
                id: i,
                label: `Show ${i} Entries`,
            })),
            filtro: false,
            reviewFilter: null,
        };
    },
    mounted() {
        this.getReview();
    },
    watch: {
        perPage(newVal) {
            if (!this.filtro) {
                this.getReview();
            } else {
                this.searchFilter(this.reviewFilter);
            }
        },
    },
    methods: {
        getReview() {
            axiosAC
                .reviewSystemList(this.perPage)
                .then(({ data, current_page, total, per_page, ...res }) => {
                    this.items = data;
                    this.totalUsers = total;
                    this.dataMeta.of = total;
                    this.dataMeta.from = res.from;
                    this.dataMeta.to = res.to;
                    this.currentPage.page = current_page;
                });
        },
        editReview(item) {
            this.isEditReviewSidebarActive = true;
            this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
        },
        editReviewSuccess(value) {
            this.isEditReviewSidebarActive = false;
            this.pageChanged(this.currentPage.page);
        },
        createReview(status) {
            this.isAddNewReviewSidebarActive = false;
            this.pageChanged(this.currentPage.page);
        },
        deleteReview(item) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    axiosAC.reviewSystemDelete(item.id).then((response) => {
                        this.$refs.toast.success(
                            "Review type has been deleted successfully"
                        );
                        this.pageChanged(this.currentPage.page);
                    });
                }
            });
        },
        pageChanged(page) {
            if (!this.filtro) {
                axiosAC
                    .reviewSystemPagination(this.perPage, page)
                    .then(({ data, total, current_page, ...res }) => {
                        this.items = data;
                        this.totalUsers = total;
                        this.dataMeta.of = total;
                        this.dataMeta.from = res.from;
                        this.dataMeta.to = res.to;
                        this.currentPage.page = current_page;
                    });
            } else {
                axiosAC
                    .reviewSystemFilterPagination(this.perPage, page, this.reviewFilter)
                    .then(({ data, total, current_page, ...res }) => {
                        this.items = data;
                        this.totalUsers = total;
                        this.dataMeta.of = total;
                        this.dataMeta.from = res.from;
                        this.dataMeta.to = res.to;
                        this.currentPage.page = current_page;
                    });
            }
        },
        searchFilter(value) {
            if (this.filtro) {
                this.loading = true;
                this.reviewFilter = value;
                axiosAC
                    .reviewSystemFilter(this.perPage, value)
                    .then(({ registro: {data, total, current_page, ...res }}) => {
                        this.loading = false;
                        this.items = data;
                        this.totalUsers = total;
                        this.dataMeta.of = total;
                        this.dataMeta.from = res.from;
                        this.dataMeta.to = res.to;
                        this.currentPage.page = current_page;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            } else {
                this.getReview();
            }
        },
        filterPage(value) {
            this.filtro = value;
        },
    },
};
</script>

<style></style>
